import React from "react";
import { graphql } from "gatsby";
import Single from "./Single";

function SinglePage({ data, pageContext }) {
  return <Single data={data} pageContext={pageContext} type="personnel" />;
}

export default SinglePage;

export const query = graphql`
  query($slug: String!) {
    article: nodePersonnel(fields: { slug: { eq: $slug } }) {
      drupal_id
      title
      fields {
        slug
      }
      field_job_title
      field_email
      field_phone
      body {
        summary
        processed
      }
      field_linkedin_profile {
        uri
      }
      field_twitter_profile {
        uri
      }
      relationships {
        field_team_category {
          drupal_id
          name
        }
        field_photo {
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        drupalUrl
      }
    }
  }
`;
